<template>
  <v-card>
    <v-container fluid>
      <v-row
        align="center"
      >
        <v-col cols="4">
          <v-autocomplete
            v-model="selectedWorkspaces"
            :items="workspaces"
            outlined
            dense
            chips
            small-chips
            label="Workspaces"
            multiple
            hide-details
            item-text="Name"
            item-value="ID"
          />
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="selectedYear"
            :items="[2023, 2024]"
            outlined
            dense
            chips
            small-chips
            label="Year"
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="selectedMonth"
            :items="[1, 2, 3, 4, 5, 6 , 7, 8, 9, 10, 11, 12]"
            outlined
            dense
            chips
            small-chips
            label="Month"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-btn @click="handleRun" block>RUN</v-btn>
        </v-col>
      </v-row>
      <v-row
        align="center"
      >
        <v-col cols="6">
          <b>{{totalResume}}</b>
        </v-col>
      </v-row>
      <v-row
        align="center"
        v-for="(item, index) of info"
        :key="index"
      >
        <v-col cols="4"
        >
          {{ getWorkspaceName(item.workspace) }}
        </v-col>
        <v-col cols="4"
        >
        {{item.Year}}/{{item.Month}}/{{item.Day}}
        </v-col>
        <v-col cols="4"
        >
        {{item.result === 1 ? 'true' : 'false'}}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
export default {
  name: 'DtouchStatsForce',
  data: () => ({
    loading: true,
    workspaces: [],
    selectedWorkspaces: null,
    selectedYear: null,
    selectedMonth: null,
    info: null,
    totalResume: '',
  }),
  mounted () {
    this.handleGetWorkspaces()
  },
  methods: {
    handleGetWorkspaces () {
        api.getAll ('dtouch', 'v1/system/workspaces/', 0, 100000, '')
          .then(response => {             
            this.workspaces = response.data
            this.loading = false
          })
    },
    getWorkspaceName (v) {
      const aux = this.workspaces.filter(x => x.ID === v).shift()
      return `${aux ? aux.Name : null} - ${v}`
    },
    prepareResume (posWorkspace, posDay, total) {
      console.log(total)
      this.totalResume = `${posWorkspace} of ${this.selectedWorkspaces && this.selectedWorkspaces.length > 0 ? this.selectedWorkspaces.length : this.workspaces.length} [${posDay} - 31]`
    },
    async handleRun () {
      if (!this.selectedYear || !this.selectedMonth) return
      let pos = 0
      this.info = []
      const ws =  this.selectedWorkspaces && this.selectedWorkspaces.length > 0 ? this.selectedWorkspaces : this.workspaces.map(item => item.ID)
      this.prepareResume(pos, 0, ws)
      //for (let workspace of this.selectedWorkspaces && this.selectedWorkspaces.length > 0 ? this.selectedWorkspaces : this.workspaces.map(item => item.ID)) {
      for (let workspace of ws) {
        for (let i = 1; i < 32; i++) {
          let aux = await new Promise ((resolve) => axios.get(`https://www.dtouch.es/prepare-cron-access/${workspace}/${this.selectedYear}/${this.selectedMonth}/${i}`)
            .then(response => {
              resolve(response.data ? response.data.shift() : null)
            })
          )
          if (aux) {
            //console.log(aux)
            aux.result = await new Promise ((resolve) => axios.post(`https://www.dtouch.es/prepare-cron-access/${workspace}/`, aux)
              .then(response => {
                //console.log(response)
                resolve(response && response.data ? response.data.affectedRows : null)
              })
            )
            this.info.push({
              workspace,
              date: `${this.selectedYear}/${this.selectedMonth}`,
              ...aux,
            })
          }
          this.prepareResume(pos, i+1, ws)
        }
        pos++;
        this.prepareResume(pos, 31, ws)
      }
      alert ('complete!!!')
    },
  },
}
</script>

